/** @jsx jsx */
import ExhibitorsTemplate from '@bottlebooks/gatsby-theme-event-pages/src/templates/ExhibitorsTemplate';
import { graphql } from 'gatsby';

export default ExhibitorsTemplate;

export const pageQuery = graphql`
  fragment wosa_ExhibitorsTemplate_Bottlebooks_Event on Bottlebooks_Event {
    collectionId: eventId
    exhibitors {
      nodes {
        exhibitorId
        ...bb_ExhibitorsPage
      }
    }
    registrations {
      nodes {
        ...exhibitorFilters
        ...wosa_exhibitorFilters
      }
    }
    registeredBrands {
      nodes {
        partnerId
        ...bb_ExhibitorsPage_RegisteredBrand
        #...exhibitorFilters_RegisteredBrand
      }
    }
    # This would bring down the api server, so it's disabled by default.
    # registeredProducts {
    #   ...bb_BrandFilters_RegisteredProductConnection
    # }
  }

  query wosa_ExhibitorsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        ...wosa_ExhibitorsTemplate_Bottlebooks_Event
      }
    }
    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
        ...exhibitorFilter_Exhibitor
        ...exhibitorMultiEventFilter
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment ExhibitorsTemplate_Event on Event {
    # This is how we should do this in the future - take the settings from event
    # But for the moment, we are loading them from environment variables using useSiteConfig
    # filterSeekingDistribution
    # filterHasDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
